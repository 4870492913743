<template>
  <div>
    <!-- <section class="speciality-area">
    </section> -->
  </div>
</template>
  
  <script>
export default {};
</script>
  
  <style scoped>
.recentPosts {
  border-bottom: 1px dashed black;
}
.blogTitle {
  border-bottom: 1px dashed black;
}
</style>