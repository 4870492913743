<template>
  <div>
    <section class="features-area" id="features-area">
      <div class="container"> 
        <div class="section-heading">
          <h2 class="journalHead">Welcome To INDJCST</h2>
          <p>
            Indian Journal of Computer Science and Technology (INDJCST) is (e-ISSN: 2583-5300), 3-Issues per
            Year, peer-reviewed, open-access online journal published for the enhancement of research in disciplines
            of Computer Science & Technologies.
          </p>
          <p>Journal for publishing new ideas founded by academicians, educationists, engineers, and corporate people.
            The research results and fundamental advancement are all aspects of Computer Science & Technology. INDJCST
            is a scholarly, open, accessible, Peer Review Journal that helps an academic person and student community.
            INDJCST provides the academic community and industry for submitting original research and applications
            related to Computer Science & Technologies.
          </p>
          <p>Publishing an article in a Peer-Reviewed Journal is critical for developing a cohesive and acknowledged
            knowledge network. It immediately reflects the authors and the institutions that support them's work
            excellence. Articles that have been peer-reviewed support and represent the scientific process. As a result,
            it is critical to agree on required ethical conduct norms.
          </p>
          <p>UGC-CARE (India) recommends publishing papers in either peer reviewed journals (refereed journals), or in
            the journals approved by UGC-CARE, or journals indexed in Scopus or Web of Science. IJIRE is a peer reviewed
            journal (refereed journals) and therefore fitting within UGC's recommendations.
          </p>
        </div>
        <div class="particulars">
          <h3>Journal particulars :</h3>
          <div class="row">
            <div class="col">
              <ul>
                <li>
                  <p>
                    <b>Title :</b>Indian Journal of Computer Science and
                    Technology
                  </p>
                </li>
                <li>
                  <p><b>Frequency :</b>3 issues per year</p>
                </li>
                <li>
                  <p><b>ISSN :</b>Applied</p>
                </li>
                <li>
                  <p>
                    <b>Publisher :</b>Fifth Dimension Research Publication (P)
                    Ltd.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Editor-in-Chief :</b>Dr.S.Sivaganesan (Professor & Head)
                  </p>
                </li>
                <li>
                  <p>
                    <b>Copyright :</b>Fifth Dimension Research Publication (P)
                    Ltd.
                  </p>
                </li>
                <li>
                  <p><b>Starting Year :</b>2022</p>
                </li>
              </ul>
            </div>
            <div class="col">
              <ul>
                <li>
                  <p><b>DOI:</b>10.59256/indjcst</p>
                </li>
                <li>
                  <p><b>Subject :</b> Computer Science and Technology</p>
                </li>
                <li>
                  <p><b>Language :</b>English</p>
                </li>
                <li>
                  <p><b>Publication Format :</b>Online</p>
                </li>
                <li>
                  <p><b>Email Id :</b>editorinchief@indjcst.com</p>
                </li>
                <li>
                  <p><b>Mobile No :</b>+91 9840521421</p>
                </li>
                <li>
                  <p><b>Website :</b>www.indjcst.com</p>
                </li>
                <li>
                  <p>
                    <b>Address :</b> Fifth Dimension research Publication (P)
                    Ltd. No: 38D5F, Rajaji nagar, Ariyalur-621713. Tamilnadu,
                    India.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInLeft">
            <div class="feature-box">
              <div class="f-point">
                01
              </div>
              <div class="feature">
                <div class="f-icon">
                  <svg enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0">
                      <stop offset="0" stop-color="#9B6646" />
                      <stop offset="1" stop-color="#9B6646" />
                    </linearGradient>
                    <g>
                      <g>
                        <path
                          d="m266.606 4.393c-.001-.001-.002-.002-.003-.003-2.809-2.807-6.657-4.39-10.603-4.39h-210c-8.284 0-15 6.716-15 15v482c0 8.284 6.716 15 15 15h300c8.284 0 15-6.716 15-15v-392c0-3.922-1.562-7.775-4.394-10.607zm4.394 46.82 38.787 38.787h-38.787zm60 430.787h-270v-452h180v75c0 8.284 6.716 15 15 15h75zm105-392c-24.813 0-45 20.187-45 45v258.574c0 31.547 12.536 62.176 34.394 84.033 2.928 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.393c21.858-21.857 34.394-52.486 34.394-84.033v-258.574c0-24.813-20.187-45-45-45zm15 303.574c0 17.899-5.346 35.365-15 49.857-9.654-14.491-15-31.958-15-49.857v-92.574h30zm0-122.574h-30v-136c0-8.271 6.729-15 15-15s15 6.729 15 15zm-255-113.719c-9.194-4.029-19.336-6.281-30-6.281-41.355 0-75 33.645-75 75s33.645 75 75 75c10.664 0 20.806-2.252 30-6.281 9.194 4.029 19.336 6.281 30 6.281 41.355 0 75-33.645 75-75s-33.645-75-75-75c-10.664 0-20.806 2.252-30 6.281zm-29.96 113.718c-.014 0-.027.001-.04.001-24.813 0-45-20.187-45-45s20.187-45 45-45c.013 0 .027.001.04.001-9.437 12.544-15.04 28.128-15.04 44.999s5.603 32.455 15.04 44.999zm29.96-11.494c-9.199-8.245-15-20.208-15-33.505s5.801-25.26 15-33.505c9.199 8.245 15 20.208 15 33.505s-5.801 25.26-15 33.505zm75-33.505c0 24.813-20.187 45-45 45-.013 0-.027-.001-.04-.001 9.437-12.544 15.04-28.128 15.04-44.999s-5.603-32.455-15.04-44.999c.014 0 .027-.001.04-.001 24.813 0 45 20.187 45 45zm15 105h-180c-8.284 0-15 6.716-15 15s6.716 15 15 15h180c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-30 60h-120c-8.284 0-15 6.716-15 15s6.716 15 15 15h120c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                          fill="url(#SVGID_1_)" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="f-description">
                  <h3>Why Choose Us</h3>
                  <ul>
                    <li>
                      <p>High Visibility & Indexing</p>
                    </li>
                    <li>
                      <p>Strict Plagiarism policy</p>
                    </li>
                    <li>
                      <p>Open Access Journal</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInUp">
            <div class="feature-box">
              <div class="f-point">
                02
              </div>
              <div class="feature">
                <div class="f-icon">
                  <img src="@/assets/Images/OpenAccess.png" width="44" height="50" />
                </div>
                <div class="f-description">
                  <h3>Author Notification </h3>
                  <ul>
                    <li>
                      <p>Status Notification: with in 24Hrs</p>
                    </li>
                    <li>
                      <p>Every Process: Email & SMS</p>
                    </li>
                    <li>
                      <p>24*7 authors query
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInRight">
            <div class="feature-box">
              <div class="f-point">
                03
              </div>
              <div class="feature">
                <div class="f-icon">
                  <svg id="f_box_3" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"
                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g>
                      <g>
                        <path
                          d="m256 0c-124.065 0-225 101.383-225 226v121c0 8.284 6.716 15 15 15h47.58c6.192 17.458 22.865 30 42.42 30h30c8.284 0 15-6.716 15-15v-151c0-8.284-6.716-15-15-15h-30c-19.555 0-36.228 12.541-42.42 30h-32.58v-15c0-108.075 87.477-196 195-196s195 87.925 195 196v15h-32.58c-6.192-17.459-22.865-30-42.42-30h-30c-8.284 0-15 6.716-15 15v151c0 8.284 6.716 15 15 15h30c19.555 0 36.228-12.542 42.42-30h32.58v15c0 24.813-20.187 45-45 45h-90c-41.355 0-75 33.645-75 75 0 8.284 6.716 15 15 15s15-6.716 15-15c0-24.813 20.187-45 45-45h90c41.355 0 75-33.645 75-75 0-33.266 0-119.897 0-151 0-124.617-100.935-226-225-226zm-135 256c0-8.271 6.729-15 15-15h15v121h-15c-8.271 0-15-6.729-15-15zm-30 15v61h-30v-61zm300 76c0 8.271-6.729 15-15 15h-15v-121h15c8.271 0 15 6.729 15 15zm60-15h-30v-61h30z"
                          fill="url(#SVGID_1_)" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="f-description">
                  <h3>Editorial Management System</h3>
                  <ul>
                    <li>
                      <p>Unique Account for Author</p>
                    </li>
                    <li>
                      <p>Review Status(Peer review report)</p>
                    </li>
                    <li>
                      <p>FAQ's & Knowledgebase for Article process</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FeaturesStyle1",
};
</script>

<style scoped>
p {
  text-align: left;
  margin-bottom: 5px;
}

.section-heading {
  width: 100%;
}

.journalHead {
  color: #9b6646;
}
</style>
